import React from "react";

import { Hint } from "@progress/kendo-react-labels";
import { Button as KRButton } from "@progress/kendo-react-buttons";

import { faExclamationCircle, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../pages/ReportingView/css/PureBox.css";

const ProfileError = ({ onClick = null }) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        } else {
            window.location.reload(true);
        }
    };

    return (
        <div style={{ height: "80vh" }}>
            <div className="d-flex h-100 align-items-center justify-content-center">
                <div className="pure-box-display" style={{ width: "375px", height: "280px" }}>
                    <div className="d-flex flex-column align-items-center my-auto text-center">
                        <div className="mb-3">
                            <FontAwesomeIcon icon={faExclamationCircle} className="text-danger" size="4x" />
                        </div>
                        <p style={{ fontSize: "0.9rem" }}>
                            There was an issue. <br /> This is most likely a bug.
                        </p>
                        <KRButton size="small" onClick={handleClick}>
                            <FontAwesomeIcon icon={faSyncAlt} size="sm" className="me-2" />
                            Refresh
                        </KRButton>
                    </div>
                    <Hint className="mt-auto mx-auto">The issue has automatically been reported to Cory.</Hint>
                </div>
            </div>
        </div>
    );
};

export default ProfileError;
