import React from "react";
import { Badge } from "react-bootstrap";

import "../DemoPreview/CellComponents/css/StatusCell.css";

const MyBadge = (props) => {
    return (
        <Badge pill className={props.className}>
            {props.children}
        </Badge>
    );
};

export default MyBadge;
