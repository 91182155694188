import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { debounce } from "../../helpers/stringUtils";

import apiCall from "../api/apiCall";
import useLocalStorage from "../../helpers/useLocalStorage";

import { Dialog } from "@progress/kendo-react-dialogs";
import { Hint } from "@progress/kendo-react-labels";

import RecentSearchItem from "./RecentSearchItem";
import SearchItem from "./SearchItem";

const SEARCH_URI = "GlobalSearch";

const SearchDialog = ({ toggle }) => {
    const history = useHistory();
    const [recent, setRecent] = useLocalStorage("recentSearch", []);
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(0);
    const globalIdCount = useRef(0);
    const selectedRef = useRef(0);
    const itemRef = useRef(null);

    const handleToggle = () => {
        let clickedItem = Object.values(options)
            .flat()
            .find((item) => item.globalId === selected);
        if (clickedItem) {
            clickedItem.globalId = undefined;
            setRecent((recent) => {
                let newRecent = [...recent];
                if (newRecent.length >= 6) {
                    newRecent.splice(5);
                }
                let isUnique = newRecent.every((item) => item.link !== clickedItem.link);
                if (!isUnique) {
                    return newRecent;
                }
                newRecent = [clickedItem, ...newRecent];
                globalIdCount.current = newRecent.length;
                return newRecent;
            });
        }
        toggle();
    };
    const latestQueryRef = useRef("");
    const [loading, setLoading] = useState(false);

    const handleSearch = debounce(async (searchQuery) => {
        latestQueryRef.current = searchQuery;

        try {
            // If the search query is not the same as the current input value, do not proceed

            if (!searchQuery || searchQuery.length < 2) {
                setInputValue("");
                setOptions({});
                globalIdCount.current = recent.length;
                return;
            }
            setLoading(true);
            setInputValue(searchQuery);
            let result = await apiCall.get(`${SEARCH_URI}`, { params: { q: searchQuery } });
            if (searchQuery !== latestQueryRef.current) {
                return;
            }

            let { Properties = [], Agreements = [], Businesses = [], Contacts = [] } = result.data;

            let currId = 0;
            if (Properties.length > 0) {
                Properties.forEach((p) => {
                    p.globalId = currId;
                    p.link = `/properties/view/${p.PropertyId}`;
                    p.type = "property";
                    currId += 1;
                });
            }

            if (Agreements.length > 0) {
                Agreements.forEach((a) => {
                    a.globalId = currId;
                    a.link = `/agreements/view/${a.ContractId}`;
                    a.type = "agreement";
                    currId += 1;
                });
            }
            if (Businesses.length > 0) {
                Businesses.forEach((a) => {
                    a.globalId = currId;
                    a.link = `/businesses/view/${a.CompanyId}`;
                    a.type = "business";
                    currId += 1;
                });
            }
            if (Contacts.length > 0) {
                Contacts.forEach((a) => {
                    a.globalId = currId;
                    a.link = `/contacts/view/${a.ContactId}`;
                    a.type = "contact";
                    currId += 1;
                });
            }
            selectedRef.current = 0;
            setOptions(() => {
                return [...Properties, ...Agreements, ...Businesses, ...Contacts];
            });
            // setOptions(() => {
            //     return { Businesses, Contacts, Properties, Agreements };
            // });
            globalIdCount.current = currId;
            // itemRef.current = Properties.length > 0 ? Properties[0] : Agreements[0];
            const newSelect =
                Businesses[0]?.globalId ??
                Contacts[0]?.globalId ??
                Properties[0]?.globalId ??
                Agreements[0]?.globalId ??
                0;
            itemRef.current = newSelect;
            setSelected(newSelect);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, 400);

    const handleSearchClear = () => {
        if (inputRef.current) {
            inputRef.current.value = "";
        }
        setInputValue("");
        setOptions([]);
    };
    const [scrolling, setScrolling] = useState(false);

    const onScroll = () => {
        if (scrolling === false) {
            setScrolling(true);
        }
    };

    const removeFromRecent = (index) => {
        setRecent((recent) => {
            let newRecent = [...recent];
            newRecent.splice(index, 1);
            return newRecent;
        });
    };

    const [scrollSource, setScrollSource] = useState(null);

    const onMouseEnter = (item) => {
        if (scrollSource !== "mouse") {
            setScrollSource("mouse");
        }
        setSelected(item.globalId);
        selectedRef.current = item.globalId;
    };

    const keyTimeout = useRef(null);

    useEffect(() => {
        const handleKeyDown = (e) => {
            // Use arrow keys to navigate through search items
            if (e.key === "ArrowDown") {
                e.preventDefault();
                setSelected((prev) => {
                    const newSelected = Math.min(prev + 1, Math.abs(globalIdCount.current - 1));
                    selectedRef.current = newSelected;
                    return newSelected;
                });
                setScrollSource("keyboard");

                clearTimeout(keyTimeout.current);

                keyTimeout.current = setTimeout(() => {
                    console.log("Timeout fired");

                    setScrollSource(null);
                }, 300);
            } else if (e.key === "ArrowUp") {
                e.preventDefault();
                setSelected((prev) => {
                    const newSelected = Math.max(prev - 1, 0);
                    selectedRef.current = newSelected;
                    return newSelected;
                });
                setScrollSource("keyboard");

                clearTimeout(keyTimeout.current);

                keyTimeout.current = setTimeout(() => {
                    console.log("Timeout fired");

                    setScrollSource(null);
                }, 300);
            } else if (e.key === "Enter") {
                const selectedElement = document.getElementById(`option-${selectedRef.current}`);
                const anchorTag = selectedElement.querySelector("a");
                const hrefValue = anchorTag.getAttribute("href");

                if (hrefValue) {
                    if (itemRef?.current) {
                        setRecent((recent) => {
                            let newRecent = [...recent];
                            if (newRecent.length >= 6) {
                                newRecent.splice(5);
                            }
                            let clickedItem = itemRef.current;
                            let isUnique = newRecent.every((item) => item.link !== clickedItem.link);
                            if (!isUnique) {
                                return newRecent;
                            }
                            clickedItem.globalId = undefined;
                            newRecent = [clickedItem, ...newRecent];
                            globalIdCount.current = newRecent.length;
                            return newRecent;
                        });
                    }

                    history.push(hrefValue);
                    toggle();
                }
            }
            setScrolling(false);
        };
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            clearTimeout(keyTimeout);
        };
    }, [history, toggle, setRecent]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target && event.target.classList.contains("k-overlay")) {
                toggle && toggle();
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [toggle]);

    const noResults = Object.values(options).every((option) => option.length === 0);

    return (
        <Dialog title="Search" height={"95vh"} width={"50vw"} onClose={toggle} className="no-overflow-dialog">
            <div className="d-flex justify-content-center global-search">
                {!loading ? (
                    <svg width="1em" height="1em" viewBox="0 0 20 20" className="me-3">
                        <path
                            d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            fillRule="evenodd"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ) : (
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 50 50"
                        className="me-3 loading-svg"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="25"
                            cy="25"
                            r="20"
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="5"
                            strokeDasharray="100 150"
                        />
                    </svg>
                )}
                <input
                    className="global-search-input"
                    placeholder="Search"
                    onChange={(e) => handleSearch(e.target.value)}
                    autoFocus
                    ref={inputRef}
                />
                {inputValue ? (
                    <button
                        type="reset"
                        title="Clear the query"
                        className="global-search-clear"
                        onClick={handleSearchClear}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20">
                            <path
                                d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z"
                                stroke="currentColor"
                                fill="none"
                                fillRule="evenodd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                ) : null}
            </div>
            <Hint className="ms-4">Results will include properties, agreements, businesses, and contacts</Hint>

            <div
                className="mt-4 small-scrollbar"
                style={{ overflowY: "scroll", maxHeight: "75vh" }}
                onScroll={onScroll}
            >
                {!inputValue && Object.values(options).length === 0 ? (
                    recent.length > 0 ? (
                        <div className="px-2 my-1">
                            <header
                                className="global-search-header text-muted text-muted-small"
                                style={{ textTransform: "uppercase" }}
                            >
                                Recent
                            </header>
                            <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                                {recent.map((item, index) => {
                                    return (
                                        <RecentSearchItem
                                            onMouseEnter={() => {
                                                setSelected(index);
                                                selectedRef.current = index;
                                            }}
                                            item={item}
                                            handleToggle={handleToggle}
                                            index={index}
                                            selected={selected}
                                            scroll={scrolling}
                                            removeFromRecent={removeFromRecent}
                                            key={`recent-${index}`}
                                        />
                                    );
                                })}
                            </ul>
                        </div>
                    ) : (
                        <div className="px-2 my-4 text-muted text-center">No recent searches</div>
                    )
                ) : null}
                {inputValue && loading ? (
                    <div className="px-2 my-4 text-muted text-center">Loading results...</div>
                ) : null}
                {inputValue && noResults && !loading ? (
                    <div className="px-2 my-4 text-muted text-center">
                        No results for <b>{inputValue}</b>
                    </div>
                ) : null}
                <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                    {Array.isArray(options) &&
                        options?.map((o, idx) => {
                            return (
                                <SearchItem
                                    key={o.globalId}
                                    index={idx}
                                    item={o}
                                    onMouseEnter={onMouseEnter}
                                    handleToggle={handleToggle}
                                    selected={selected}
                                    scrolling={scrolling}
                                    scrollSource={scrollSource}
                                />
                            );
                        })}
                </ul>
            </div>
        </Dialog>
    );
};

export default SearchDialog;
