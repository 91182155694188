import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import { emptyMongoApi } from "./services/emptyMongoApi";

import settingsReducer from "./reducers/settings/settingsSlice";
import viewReducer from "./reducers/views/viewSlice";
import infoReducer from "./reducers/info/infoSlice";
import rulesReducer from "./reducers/rules/rulesSlice";
import errorsReducer from "./reducers/errors/errorsSlice";
import commandCellReducer from "./reducers/grid-actions/commandCellSlice";

const persistConfig = {
    key: "root",
    version: 1,
    storage: storageSession,
    whitelist: ["views"],
};

const commandCellConfig = {
    key: "commandCells",
    version: 1,
    storage: storage,
};

const rootReducer = combineReducers({
    settings: settingsReducer,
    views: viewReducer,
    info: infoReducer,
    rules: rulesReducer,
    errors: errorsReducer,
    commandCells: persistReducer(commandCellConfig, commandCellReducer),
    [emptyMongoApi.reducerPath]: emptyMongoApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(emptyMongoApi.middleware),
});

const persistor = persistStore(store);

setupListeners(store.dispatch);

export { store, persistor };
