import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { colorMap } from "../TileProfiles/helpers/colorMap";

import { Button as KRButton } from "@progress/kendo-react-buttons";
import { Hint } from "@progress/kendo-react-labels";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAddressBook,
    faBuilding as farBuilding,
    faHandshake as farHandshake,
} from "@fortawesome/free-regular-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import MyBadge from "./MyBadge";

const RecentSearchItem = ({ item, index, selected, handleToggle, onMouseEnter, scroll, removeFromRecent }) => {
    const ref = useRef(null);

    const handleRecent = () => {
        removeFromRecent(item);
    };

    useEffect(() => {
        if (!scroll && index === selected) {
            ref.current?.scrollIntoView({ block: "center", inline: "nearest" });
        }
    }, [scroll, index, selected]);

    return (
        <li
            id={`option-${index}`}
            className={`mt-3 global-search-item ${colorMap[item.Status]}`}
            role="option"
            aria-selected={index === selected}
            key={index}
        >
            {item.type === "property" ? (
                <Link
                    to={`/properties/view/${item.PropertyId}`}
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={handleToggle}
                    ref={ref}
                >
                    <div className="global-search-result d-flex flex-row align-items-center">
                        <div>
                            <FontAwesomeIcon icon={farBuilding} fixedWidth className="me-4" size="lg" />
                        </div>
                        <div className="d-flex flex-column">
                            <div>{item.PropertyName}</div>
                            <div className="d-flex align-items-center mb-1">
                                {item.Units ? (
                                    <span
                                        className="k-chip k-chip-sm k-rounded-sm k-chip-solid k-chip-solid-base me-1"
                                        style={{ fontSize: "12px" }}
                                    >
                                        {item.Units} units
                                    </span>
                                ) : null}
                                {item.PropertyAddress ? (
                                    <span
                                        className="k-chip k-chip-sm k-rounded-sm k-chip-solid k-chip-solid-base me-1"
                                        style={{ fontSize: "12px" }}
                                    >
                                        {item.PropertyAddress}
                                    </span>
                                ) : null}
                                {item.PreviousPropertyName ? (
                                    <span
                                        className="k-chip k-chip-sm k-rounded-sm k-chip-solid k-chip-solid-base me-1"
                                        style={{ fontSize: "12px" }}
                                    >
                                        {item.PreviousPropertyName}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <Hint className="ms-auto me-3 text-muted" style={{ fontSize: "10px" }}>
                            <div className="me-4">
                                <MyBadge className={`large-badge ${colorMap[item.Status]}`}>{item.Status}</MyBadge>
                            </div>
                            <span style={{ minWidth: "60px" }}>Property</span>
                        </Hint>
                    </div>
                </Link>
            ) : null}
            {item.type === "agreement" ? (
                <Link
                    to={`/agreements/view/${item.ContractId}`}
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={handleToggle}
                    ref={ref}
                >
                    <div className="global-search-result d-flex flex-row align-items-center">
                        <div>
                            <FontAwesomeIcon icon={farHandshake} fixedWidth className="me-4" size="lg" />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center">
                                {item.ClientNames}
                                <Hint className="text-muted mx-2">({item.ContractDispId})</Hint> &bull;
                                <div className="mx-2">{item.PropertyNames}</div>
                            </div>
                            <div className="d-flex align-items-center mb-1">
                                <span
                                    className="k-chip k-chip-sm k-rounded-sm k-chip-solid k-chip-solid-base me-1"
                                    style={{ fontSize: "12px" }}
                                >
                                    {item.ProviderName}
                                </span>
                                <span
                                    className="k-chip k-chip-sm k-rounded-sm k-chip-solid k-chip-solid-base mx-1"
                                    style={{ fontSize: "12px" }}
                                >
                                    {item.ServiceCategories}
                                </span>
                                <span
                                    className="k-chip k-chip-sm k-rounded-sm k-chip-solid k-chip-solid-base mx-1"
                                    style={{ fontSize: "12px" }}
                                >
                                    {item.Services}
                                </span>
                            </div>
                        </div>
                        <Hint className="ms-auto me-3 text-muted" style={{ fontSize: "10px" }}>
                            <div className="me-4">
                                <MyBadge className={`large-badge ${colorMap[item.Status]}`}>{item.Status}</MyBadge>
                            </div>
                            <span style={{ minWidth: "60px" }}>Agreement</span>
                        </Hint>
                    </div>
                </Link>
            ) : null}
            {item.type === "contact" ? (
                <Link
                    to={`/contacts/view/${item.ContactId}`}
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={handleToggle}
                    ref={ref}
                >
                    <div className="global-search-result d-flex flex-row align-items-center">
                        <div>
                            <FontAwesomeIcon icon={faAddressBook} fixedWidth className="me-4" size="lg" />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="global-search-result-name">{item.ContactName}</div>
                        </div>
                        <Hint className="ms-auto me-3 text-muted" style={{ fontSize: "10px" }}>
                            <div className="me-4">
                                <MyBadge className={`large-badge ${colorMap[item.Status]}`}>{item.Status}</MyBadge>
                            </div>
                            <span style={{ minWidth: "60px" }}>Contact</span>
                        </Hint>
                    </div>
                </Link>
            ) : null}
            {item.type === "business" ? (
                <Link
                    to={`/businesses/view/${item.CompanyId}`}
                    style={{ color: "black", textDecoration: "none" }}
                    onClick={handleToggle}
                    ref={ref}
                >
                    <div className="global-search-result d-flex flex-row align-items-center">
                        <div>
                            <FontAwesomeIcon icon={faBriefcase} fixedWidth className="me-4" size="lg" />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="global-search-result-name">{item.CompanyName}</div>
                            <Hint>{item.CompanyType}</Hint>
                        </div>
                        <Hint className="ms-auto me-3 text-muted" style={{ fontSize: "10px" }}>
                            <div className="me-4">
                                <MyBadge className={`large-badge ${colorMap[item.Status]}`}>{item.Status}</MyBadge>
                            </div>
                            <span style={{ minWidth: "60px" }}>Business</span>
                        </Hint>
                    </div>
                </Link>
            ) : null}
            <KRButton
                size="small"
                fillMode={"flat"}
                className="remove-search j-100"
                title="Remove"
                style={{ background: "transparent" }}
                onClick={handleRecent}
            >
                <svg width="20" height="20" viewBox="0 0 20 20">
                    <path
                        d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z"
                        stroke="currentColor"
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    ></path>
                </svg>
            </KRButton>
        </li>
    );
};

export default RecentSearchItem;
