import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { useLocation, Link } from "react-router-dom";

import { useColor } from "../../providers/ColorProvider";
import { PusherContext } from "../../providers/Pusher";
import { useDialogLogic } from "../../helpers/useDialogLogic";

import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Alert from "react-bootstrap/Alert";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { Button as KRButton } from "@progress/kendo-react-buttons";
import { Hint } from "@progress/kendo-react-labels";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonDigging, faHelmetSafety, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import GlobalSearch from "./NewGlobalSearch";
import ErrorToast from "./ErrorToast";
import SuccessToast from "./SuccessToast";
import AccountSettings from "./AccountSettings";
import UserAvatar from "./UserAvatar";

import "./css/Header.css";

const UserInfo = () => {
    const {
        accessTokenPayload: { userName, userEmail },
    } = useSessionContext();
    return (
        <div className="px-3 mb-3">
            <Hint>{userName}</Hint>
            <Hint>{userEmail}</Hint>
        </div>
    );
};
const AppInfo = () => {
    const socketId = useSelector((state) => state.info.socketId ?? "");
    return (
        <div className="d-flex flex-row mb-2">
            <div className="header-account-info">{socketId}</div>
            <div className="header-account-info ms-auto">v.{window.__APP_VERSION__}</div>
        </div>
    );
};

const Header = () => {
    const location = useLocation();
    const [isSignout, setIsSignout] = useState(false);
    const [message, setMessage] = useState("Signout Successful! Have a good day!");
    const [error, setError] = useState(false);

    const { show: settings, toggleShow: toggleSettings } = useDialogLogic();

    const stickyHeaders = useSelector((state) => state.settings.general.stickyHeaders ?? true);

    const pusherError = useSelector((state) => state.errors.pusher ?? false);
    const { pusher } = useContext(PusherContext);
    const { setColor } = useColor();

    const handleSignOut = async () => {
        try {
            await signOut();
            pusher.disconnect();
            sessionStorage.clear();
            window.location.href = "/auth";
        } catch (error) {
            console.log(error);
            setMessage("Uh Oh! Something went wrong. Try again?");
            setError(true);
        }
    };

    const toggleSignout = () => {
        setIsSignout(() => false);
    };

    const toggleError = () => {
        setError(() => false);
    };

    return (
        <div className={stickyHeaders ? "header-sticky" : null}>
            {document.location.origin.includes("demo") && (
                <Alert
                    style={{
                        width: "275px",
                        position: "absolute",
                        top: 3,
                        left: 0,
                        zIndex: "80000",
                        fontSize: "12px",
                    }}
                    className="m-1 p-2"
                >
                    <FontAwesomeIcon icon={faPersonDigging} className="mx-1" fixedWidth />
                    This is the Foliedge DEMO site. <br /> Not the production version of Foliedge.
                    <FontAwesomeIcon icon={faHelmetSafety} className="mx-1" fixedWidth />
                </Alert>
            )}
            <div className="d-flex align-items-center">
                <div className="header-image-container">
                    <div className="header-image">
                        <img
                            className="foliedge-logo"
                            src={"https://asm-static-files.s3.amazonaws.com/logos/new_logo.png"}
                            alt="FoliEdge logo"
                            title={`FoliEdge logo`}
                        />
                    </div>
                    <div className="header-image" style={{ position: "relative" }}>
                        <a href="https://asmadvantage.com/" target="_blank" rel="noopener noreferrer">
                            <img
                                className="asm-logo"
                                src={"https://asm-static-files.s3.amazonaws.com/logos/ASM_Est2003_Logo_min.png"}
                                alt="ASM logo"
                            />
                        </a>
                    </div>
                </div>
                <div className="ms-auto mt-3" style={{ width: "fit-content" }}>
                    <InputGroup className="mb-3">
                        <GlobalSearch />
                    </InputGroup>
                </div>

                <div className="d-flex flex-row ms-auto me-5 align-items-center">
                    {pusherError ? (
                        <OverlayTrigger
                            placement={"bottom"}
                            overlay={
                                <Tooltip className="real-time-error">
                                    There was an issue subscribing to real-time updates. Please refresh the page to try
                                    again.
                                </Tooltip>
                            }
                        >
                            <KRButton
                                fillMode="flat"
                                onClick={() => window.location.reload(true)}
                                className="d-flex flex-column me-3"
                            >
                                <FontAwesomeIcon icon={faExclamationCircle} className="text-danger" size="2x" />
                            </KRButton>
                        </OverlayTrigger>
                    ) : (
                        <div style={{ width: "50px" }} />
                    )}
                    <Dropdown className="main-header-dropdown">
                        <Dropdown.Toggle className="bg-white border-0 p-0" id="user-settings-dropdown">
                            <UserAvatar />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="p-3">
                            <Dropdown.Header as={AppInfo} />

                            <Dropdown.Header as={UserInfo} />
                            {location?.pathname?.includes("/admin") ? (
                                <Dropdown.Item as={Link} to="/dashboards">
                                    Return to Dashboard
                                </Dropdown.Item>
                            ) : (
                                <Dropdown.Item as={Link} to="/admin">
                                    Admin
                                </Dropdown.Item>
                            )}
                            <Dropdown.Item>Help</Dropdown.Item>
                            <Dropdown.Item onClick={toggleSettings}>Account Settings</Dropdown.Item>
                            <Dropdown.Item onClick={handleSignOut}>Logout</Dropdown.Item>
                            <Dropdown.Divider />
                            <div className="d-flex gap-3 justify-content-center">
                                <div
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "50%",
                                        backgroundColor: "green",
                                        border: "1px solid black",
                                    }}
                                    className="clickable-icon"
                                    onClick={() => setColor({ background: "green", text: "white" })}
                                />
                                <div
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "50%",
                                        backgroundColor: "orange",
                                        border: "1px solid black",
                                    }}
                                    className="clickable-icon"
                                    onClick={() => setColor({ background: "orange", text: "black" })}
                                />
                                <div
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "50%",
                                        backgroundColor: "lightblue",
                                        border: "1px solid black",
                                    }}
                                    className="clickable-icon"
                                    onClick={() => setColor({ background: "lightblue", text: "black" })}
                                />
                                <div
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "50%",
                                        backgroundColor: "#ff6347",
                                        border: "1px solid black",
                                    }}
                                    className="clickable-icon"
                                    onClick={() => setColor({ background: "#ff6347", text: "white" })}
                                />
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <ErrorToast show={error} toggleShow={toggleError} message={message} />
                <SuccessToast show={isSignout} toggleShow={toggleSignout} message={message} />
                {settings ? <AccountSettings toggle={toggleSettings} /> : null}
            </div>
        </div>
    );
};

export default Header;
